<template>
  <div>
    <Toast position="top-right" />

    <div
      class="d-flex justify-content-center align-items-center h-75"
      v-if="carregando"
    >
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>
    <div
      v-else
      class="d-flex flex-column justify-content-between align-items-between h-100"
    >
      <div class="d-flex flex-column justify-content-between h-100 w-100">
        <b-table
          id="tabelaFluxos"
          small
          :current-page="currentPage"
          :fields="fields"
          :items="itens"
          :per-page="selectQuant.value"
          responsive="sm"
          hover
          sort-by="cfl_titulo"
          :sort-desc="false"
          ref="refteste"
          primary-key="chatbot_fluxo_id"
          @row-clicked="editarFluxo"
        >
          <template #cell(chatbot_fluxo_id)="data">
            <label class="mt-3">
              {{ data.value }}
            </label>
          </template>

          <template #cell(cfl_titulo)="data">
            <label class="mt-3">
              {{ data.value || "-" }}
            </label>
          </template>

          <template #cell(cfl_descricao)="data">
            <label class="mt-3">
              {{ data.value || "-" }}
            </label>
          </template>

          <template #cell(utilizacao)="data">
            <label
              class="mt-3"
              v-b-tooltip.hover.right
              :title="data.item.tooltip"
            >
              <div v-html="data.value"></div>
            </label>
          </template>

          <template #cell()="row">
            <i>{{ row.value }}</i>
            <b-dropdown
              no-caret
              variant="outline-secondary"
              id="dropdown-dropleft"
              dropleft
              class="border-0"
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <b-dropdown-item :href="getEditUrl(row.item.chatbot_fluxo_id)"
                >Editar
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="duplicarFluxo(row.item)"
                >Duplicar</b-dropdown-item
              >
              <b-dropdown-item
                @click="mostrarDeletarFluxoSwal(row.item)"
                href="#"
                >Remover</b-dropdown-item
              >
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex w-100">
          <b-row class="w-100">
            <b-col md="4"
              ><b-form-select
                v-model="selectQuant.value"
                :options="selectQuant.options"
              ></b-form-select
            ></b-col>
            <b-col md="8"
              ><b-pagination
                v-model="currentPage"
                :total-rows="itens.length"
                :per-page="selectQuant.value"
                aria-controls="tabelaFluxos"
                align="fill"
                @change="salvarConfiguracoes"
              ></b-pagination
            ></b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../automacao/api.links";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import store from "@/core/services/store/store";
import Swal from "sweetalert2";

export default {
  components: {
    Toast
  },
  data() {
    return {
      original_flows: [],
      fluxoSelecionado: {},
      chatbot_menus: [],
      carregando: true,
      callbacks: [],
      redirect_to_ids: {},
      fields: [
        {
          key: "chatbot_fluxo_id",
          label: "ID",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "80px !important" }
        },
        {
          key: "cfl_titulo",
          label: "Título",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "250px !important" }
        },
        {
          key: "cfl_descricao",
          label: "Descrição",
          class: "cursor-pointer align-middle",
          thStyle: { width: "250px !important" }
        },
        {
          key: "utilizacao",
          label: "Utilização",
          class: "cursor-pointer align-middle",
          thStyle: { width: "250px !important" }
        },
        {
          key: "opcoes",
          label: "Opções",
          class: "text-center",
          thStyle: { width: "60px !important" }
        }
      ],
      itens: [],
      data: {},
      currentPage: 1,
      perPage: 10,
      itemSelecionado: {},

      selectQuant: {
        value: "10",
        options: [
          {
            text: "Exibir 10 registros por página",
            value: "10"
          },
          {
            text: "Exibir 15 registros por página",
            value: "15"
          },
          {
            text: "Exibir 30 registros por página",
            value: "30"
          }
        ]
      }
    };
  },

  methods: {
    filtrarFluxos(filtros) {
      const fluxoFiltradoCategorias = this.filtrarCategoria(filtros);
      const fluxoFiltradoTitulo = this.filtrarTitulo(
        filtros,
        fluxoFiltradoCategorias
      );

      const fluxoFiltrado = fluxoFiltradoTitulo;
      this.itens = fluxoFiltrado;
    },
    filtrarCategoria(filtros) {
      const categorias = filtros["categorias"].map((categoria) => {
        return categoria.fluxo_categoria_id;
      });
      if (categorias.length == 0) {
        return JSON.parse(JSON.stringify(this.original_flows));
      }
      const filtered_flows = [];
      for (let i = 0; i < this.original_flows.length; i++) {
        const flow = this.original_flows[i];

        const flow_categories = flow.categorias.map((categoria) => {
          return categoria.fluxo_categoria_id;
        });

        if (flow_categories.some((r) => categorias.includes(r))) {
          filtered_flows.push(flow);
        }
      }
      return filtered_flows;
    },
    filtrarTitulo(filtros, items) {
      const filtrado = items.filter((f) =>
        f.cfl_titulo.toLowerCase().includes(filtros.titulo.toLowerCase())
      );
      return filtrado;
    },
    duplicarFluxo(fluxo) {
      ApiService.post(API_LINKS.chatbot_duplicar, {
        chatbot_fluxo_id: fluxo.chatbot_fluxo_id
      }).then((res) => {
        this.pegarListagemFluxos();
      });
    },
    getEditUrl(chatbot_fluxo_id) {
      return this.$router.resolve({
        name: "automacao.fluxo.editar",
        query: {
          fluxo_id: chatbot_fluxo_id
        }
      }).href;
    },
    editarFluxo(fluxo) {
      this.$router.push({
        name: "automacao.fluxo.editar",
        params: {
          fluxoObj: fluxo
        }
      });
    },
    mostrarDeletarFluxoSwal(fluxo) {
      if (fluxo.boolUtilizado) {
        Swal.fire({
          title: "Fluxo sem permissão para remoção" + fluxo.cfl_titulo,
          text: "Para remover o fluxo, o mesmo não pode ser utilizado!",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok!"
        });

        return;
      }

      this.fluxoSelecionado = fluxo;
      Swal.fire({
        title:
          'Você deseja remover o fluxo "' +
          this.fluxoSelecionado.cfl_titulo +
          '"?',
        text: "Não será possivel reverter essa ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        const fluxos_ativos = [];
        for (const [key, value] of Object.entries(this.chatbot_menus.menu)) {
          value.forEach((item) => {
            fluxos_ativos.push(item.chatbot_fluxo_id);
          });
        }

        if (result.isConfirmed) {
          if (fluxos_ativos.includes(this.fluxoSelecionado.chatbot_fluxo_id)) {
            Swal.fire(
              "Fluxo não removido",
              `Fluxo "${this.fluxoSelecionado.cfl_titulo}" não pode ser removido pois está atrelado a um item de menu.`,
              "error"
            );
            return;
          }
          Swal.fire(
            "Fluxo Removido",
            `Fluxo "${this.fluxoSelecionado.cfl_titulo}" removida.`,
            "success"
          );
          ApiService.delete(
            API_LINKS.chatbot + "/" + this.fluxoSelecionado.chatbot_fluxo_id
          ).then((_) => {
            this.pegarListagemFluxos();
          });
        }
      });
    },
    detalhes(item) {
      this.fluxoSelecionado = item;
    },
    salvarConfiguracoes(currentPage) {
      store.state.paginacao = {
        perPage: this.selectQuant.value,
        currentPage: currentPage,
        licenciadaSelecionadaFiltrada: this.licenciadaSelecionadaFiltrada
      };
    },
    async pegarMenus() {
      const response = await ApiService.get(API_LINKS.chatbot_menu);
      this.chatbot_menus = response.data;
      return response.data;
    },
    async pegarListagemFluxos() {
      this.carregando = true;
      try {
        const response = await ApiService.get(API_LINKS.chatbot_listar_fluxos);
        const flows = this.getFlowsUsage(response.data.fluxos);
        this.itens = this.updateFlowUsage(flows);
        this.original_flows = JSON.parse(JSON.stringify(this.itens));
        this.carregando = false;
      } catch (error) {
        console.error(error);
        this.carregando = false;
      }
    },
    updateFlowUsage(flows) {
      for (let i = 0; i < flows.length; i++) {
        const flow = flows[i];
        flows[i].utilizacao = "";
        flows[i].tooltip = "";
        flows[i].boolUtilizado = true;

        // concat everything that is on menu
        const menu = this.chatbot_menus.menu;
        const all_menu_items = [];
        const menu_usage_hashmap = {};
        for (const [key, value] of Object.entries(menu)) {
          value.forEach((item) => {
            menu_usage_hashmap[item.chatbot_fluxo_id] = key;
            all_menu_items.push(item.chatbot_fluxo_id);
          });
        }
        if (all_menu_items.includes(flow.chatbot_fluxo_id)) {
          flows[i].utilizacao =
            "<div class='badge bg-primary text-white mr-2'>Menu</div>";
          flows[i].tooltip = `Este fluxo é utilizado no menu ${
            menu_usage_hashmap[flow.chatbot_fluxo_id]
          }. `;
        }

        const configs = this.chatbot_menus.menuConfiguracao;
        const pre_flows = [];
        const error_treatment_flows = [];
        const extra_expedients_flows = [];
        const inactivity_flows = [];
        for (let j = 0; j < configs.length; j++) {
          const config = configs[j];
          if (config.tratamento_erro_fluxo_id) {
            error_treatment_flows.push(config.tratamento_erro_fluxo_id);
          }
          if (config.cmc_prefluxo) {
            pre_flows.push(config.cmc_prefluxo_id);
          }
          if (config.cmc_acao_inatividade) {
            inactivity_flows.push(config.cmc_acao_inatividade);
          }
          if (config.cmc_fluxo_fora_expediente) {
            extra_expedients_flows.push(config.cmc_fluxo_fora_expediente);
          }
        }

        if (pre_flows.includes(flow.chatbot_fluxo_id)) {
          flows[i].utilizacao +=
            "<div class='badge bg-secondary mr-2'>Pré-Fluxo</div>";
          flows[i].tooltip +=
            " Este fluxo é utilizado como configuração de 'Pré-Fluxo'. ";
        }

        if (error_treatment_flows.includes(flow.chatbot_fluxo_id)) {
          flows[i].utilizacao +=
            "<div class='badge bg-secondary mr-2'>Quantidade Máxima de Erros Excedida</div>";

          flows[i].tooltip +=
            " Este fluxo é utilizado como configuração de 'Quantidade Máxima de Erros Excedida'. ";
        }

        if (extra_expedients_flows.includes(flow.chatbot_fluxo_id)) {
          flows[i].utilizacao +=
            "<div class='badge bg-secondary mr-2'>Ação Fora do Expediente</div>";
          flows[i].tooltip +=
            " Este fluxo é utilizado como configuração de 'Ação Fora do Expediente'. ";
        }

        if (inactivity_flows.includes(flow.chatbot_fluxo_id)) {
          flows[i].utilizacao +=
            "<div class='badge bg-secondary mr-2'>Ação em Caso de Inatividade</div>";
          flows[i].tooltip +=
            " Este fluxo é utilizado como configuração de 'Ação em Caso de Inatividade'. ";
        }

        if (this.redirect_to_ids[flow.chatbot_fluxo_id]) {
          flows[i].utilizacao +=
            "<div class='badge bg-orange mr-2'>Estágio Redirecionamento</div>";
          flows[i].tooltip +=
            " Este fluxo é utilizado no estágio de Redirecionamento pelo(s) fluxo(s) " +
            this.redirect_to_ids[flow.chatbot_fluxo_id].join(", ") +
            ". ";
        }

        const callbacks = this.callbacks;
        for (let j = 0; j < callbacks.length; j++) {
          const callback = callbacks[j];
          if (callback.chatbot_fluxo_id == flow.chatbot_fluxo_id) {
            flows[i].utilizacao +=
              "<div class='badge bg-info text-white mr-2'>Callback</div>";
            flows[i].tooltip +=
              " Este fluxo é utilizado como callback da campanha '" +
              callback.mdc_nome +
              "'. ";
            break;
          }
        }

        if (flows[i].utilizacao.length > 0) continue;

        flows[i].utilizacao =
          "<div class='badge bg-warning'>Não Identificada</div>";
        flows[i].tooltip = "Utilização do fluxo não identificada.";
        flows[i].boolUtilizado = false;
      }
      return flows;
    },
    getFlowsUsage(flows) {
      for (let i = 0; i < flows.length; i++) {
        const flow = flows[i];
        this.checkUsageFlow(flow);
      }
      return flows;
    },
    checkUsageFlow(flow) {
      const nodes = flow.nodes;
      const node_map = {};
      const redirect_ids = [];
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        node_map[node.id] = JSON.parse(JSON.stringify(node));
        if (node.chatbot_estagio_id == 22) {
          const redirect_to = node.ces_metadata.campos.find(
            (campo) => campo.bindText == "cfe_atributo"
          );
          const redirect_to_id = +redirect_to.value;
          redirect_ids.push(redirect_to_id);
        }
      }
      for (let i = 0; i < redirect_ids.length; i++) {
        const redirect_id = redirect_ids[i];
        if (!this.redirect_to_ids[redirect_id]) {
          this.redirect_to_ids[redirect_id] = [];
        }
        this.redirect_to_ids[redirect_id].push(flow.chatbot_fluxo_id);
      }
    },
    async pegarCallbacks() {
      const response = await ApiService.get(API_LINKS.chatbot_callback);
      this.callbacks = response.data.callbacks;
      return response.data.callbacks;
    }
  },
  mounted() {
    const init = async () => {
      await this.pegarMenus();
      await this.pegarCallbacks();

      this.pegarListagemFluxos();
      this.carregando = false;
    };
    init();
  },
  watch: {
    "selectQuant.value"(newValue) {
      this.salvarConfiguracoes(this.currentPage);
    }
  }
};
</script>

<style scoped>
::v-deep .bg-orange {
  background: #e1fcb6 !important;
}
.modal-deletar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.camp-center {
  display: flex;
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.p-multiselect-trigger-icon.pi.pi-chevron-down::before {
  content: none;
}

table#tabelaFluxos .flip-list-move {
  transition: transform 0.5s;
}
</style>
